import React from "react";
import "./App.css";

function App() {
  return (
    <div className="h-[100vh] w-full flex flex-col justify-center items-center overflow-hidden bg-white">
      <div class="flex flex-col justify-center items-center">
        <div>
          <img src="/login-image.png" class="w-[300px] lg:w-[600px]" />
        </div>
      </div>

      <div class=" absolute bottom-0 mx-auto text-center text-[#FF6600]">
        <div class=" uppercase  font-bold text-[25px] lg:text-[35px]">
          <a href="https://locgroup.com.tr" target="_blank">
            Loc Group Bilişim
          </a>
        </div>
        <div class=" uppercase  font-bold text-[25px] lg:text-[35px]">
          0242 922 2022
        </div>
      </div>
    </div>
  );
}

export default App;
